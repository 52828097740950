<template>
  <a-spin size="large" :spinning="state.uploadLoading">
    <div v-if="state.isShow">
      <a-upload-dragger
        :customRequest="handleUpload"
        :accept="accept"
        name="file"
        @reject="handleReject"
        :showUploadList="false"
      >
        <div style="height: 150px">
          <p class="ant-upload-drag-icon">
            <upload-outlined></upload-outlined>
          </p>
          <p class="ant-upload-text">
            {{ $t("warehouse.click_or_drag_file_to_this_area_to_upload") }}
          </p>
        </div>
      </a-upload-dragger>
      <a-row :gutter="[32, 12]" class="mt-3">
        <a-col>
          <a-button
            :loading="state.downloadLoading"
            @click="handleDownloadTemplateFile"
            >{{ $t("common.download_template_file") }}</a-button
          >
        </a-col>
        <a-col>
          <a-popconfirm
            :title="$t('common.are_you_sure')"
            :ok-text="$t('common.confirm')"
            :cancel-text="$t('common.cancel')"
            @confirm="handleCancel"
          >
            <a-button>{{ $t("warehouse.cancel_the_reservation") }}</a-button>
          </a-popconfirm>
        </a-col>
        <a-col>
          <a-button @click="handleConfirm">{{
            $t("common.next_step")
          }}</a-button>
        </a-col>
      </a-row>
    </div>
    <div v-else>
      <a-card style="width: 400px">
        <template #title>
          <div class="text-center">
            <span class="text-success" v-if="state.isSuccess">
              <CheckCircleOutlined />{{ $t("warehouse.upload_successful") }}
            </span>
            <span class="text-error" v-else>
              <CloseCircleOutlined /> {{ $t("warehouse.fail_to_upload") }}
            </span>
          </div>
        </template>

        <a-row class="text-center" :gutter="[24, 12]" v-if="state.isSuccess">
          <a-col :span="12">{{ $t("warehouse.shipment_quantity") }}</a-col>
          <a-col :span="12" class="text-success">{{
            state.shipmentCount
          }}</a-col>
          <a-col :span="12">{{ $t("warehouse.box_amount") }}</a-col>
          <a-col :span="12" class="text-success">{{ state.boxCount }}</a-col>
          <a-col :span="24">
            <a-button type="primary" @click="handleBack">
              {{ $t("common.backtrack") }}
            </a-button>
            <a-button
              type="primary"
              class="mt-4 ml-2"
              @click="handleConfirm"
              :disabled="state.shipmentCount == 0"
            >
              {{ $t("common.confirm_and_next_step") }}
            </a-button>
          </a-col>
        </a-row>

        <div v-else>
          <a-row
            class="text-center"
            type="flex"
            justify="center"
            :gutter="[12, 12]"
          >
            <a-col :span="12">{{ $t("warehouse.error_quantity") }}</a-col>
            <a-col :span="12" class="text-error">{{ state.errorNum }}</a-col>
          </a-row>

          <a-row>
            <a-col :span="24">
              <a-row :gutter="[0, 4]" class="mt-3" type="flex" justify="start">
                <a-col>
                  <strong>{{ $t("common.error_ause") }}</strong>
                </a-col>
                <a-col
                  :span="24"
                  v-for="(errObj, index) in state?.errorData"
                  :key="index"
                >
                  {{ index + 1 }}. {{ $t("common.line_p0", [errObj.row]) }}:
                  <span v-if="errObj?.errors && errObj?.errors?.length > 0">
                    {{}}
                    <span v-for="(e, i) in errObj?.errors" :key="i">
                      {{ $t(getErrorMsgKey(e.errorCode)) }}
                      <span v-if="i != errObj?.errors?.length - 1">, </span>
                    </span>
                  </span>
                </a-col>
              </a-row>
            </a-col>
          </a-row>

          <a-row type="flex" justify="center">
            <a-col >
              <a-button type="primary" class="mt-4" @click="handleBack">
                {{ $t("common.backtrack") }}
              </a-button>
            </a-col>
          </a-row>
        </div>
      </a-card>
    </div>
  </a-spin>
</template>
<script>
import { reactive } from "vue";
import CImage from "../../../components/CImage.vue";
import {
  joinDownloadTemplateUrl,
  importShipment,
} from "../../../../api/modules/transit/inPlan/plan";
import CFileDisplay from "../../../components/CFileDisplay.vue";
import { useI18n } from "vue-i18n/index";
import urlHelper from "../../../../utils/urlHelper";
import { downloadFile } from "../../../../utils/downloader";
import { getErrorMsgKey } from "../../../../api/errorHandlers";
import { maxUploadFileSizeByte } from '../../../../utils/common';

import {
  Row,
  Col,
  Upload,
  Tooltip,
  Select,
  Button,
  Form,
  message,
  Spin,
  Card,
  Popconfirm,
} from "ant-design-vue";
export default {
  name: "CUpload",
  components: {
    CFileDisplay,
    CImage,
    ACard: Card,
    ATooltip: Tooltip,
    AUpload: Upload,
    ASpin: Spin,
    AUploadDragger: Upload.Dragger,
    AImage: Image,
    ARow: Row,
    ACol: Col,
    ASelect: Select,
    AButton: Button,
    ASelectOption: Select.Option,
    AFormItem: Form.Item,
    AForm: Form,
    APopconfirm: Popconfirm,
  },
  props: {
    planId: {
      required: true,
    },
    accept: {
      //传入后缀,不传类型,用后缀做的验证
      required: false,
      default: "",
    },
    emits: ["handleCancel", "accomplish"],
  },
  setup(props, { emit }) {
    const vueI18n = useI18n({ useScope: "global" });

    const state = reactive({
      uploadLoading: false,
      downloadLoading: false,
      errorData: null,
      boxCount: null,
      errorNum: null,
      isSuccess: null,
      shipmentCount: null,
      isShow: true,
    });

    const isVerifiedFileType = (fileName) => {
      let fileExtension = urlHelper.getUrlExtension(fileName)?.toLowerCase();
      let arrayAccept = props.accept.split(",");
      return (
        arrayAccept &&
        arrayAccept.findIndex((item) => item == fileExtension) >= 0
      );
    };

    const handleUpload = (file) => {
      if (file?.file?.size > maxUploadFileSizeByte() ) {
        message.error(vueI18n.t('common.please_upload_file_with_size_less_than_50MB'));
        return
      }

      if (props.accept && !isVerifiedFileType(file.file.name)) {
        message.error(
          vueI18n.t("common.the_format_of_the_uploaded_file_is_not_supported")
        );
        return;
      }

      state.uploadLoading = true;
      let parameter = new FormData();
      parameter.append("file", file.file);
      parameter.append("planId", props.planId);
      importShipment(parameter)
        .then(({ result }) => {
          state.isShow = false;
          Object.assign(state, result);
          state.uploadLoading = false;
        })
        .catch(() => {
          state.uploadLoading = false;
        });
    };

    //下载模板文件
    const handleDownloadTemplateFile = () => {
      state.downloadLoading = true;
      let url = joinDownloadTemplateUrl();
      downloadFile(url, "Import shipment template file.xlsx");
      state.downloadLoading = false;
    };

    //拖拽文件不符合accept
    const handleReject = (file) => {
      message.error(
        vueI18n.t("common.the_format_of_the_uploaded_file_is_not_supported")
      );
    };

    //取消预约
    const handleCancel = () => {
      emit("handleCancel");
    };

    //返回到重新上传
    const handleBack = async () => {
      state.isShow = true;
    };

    const handleConfirm = () => {
      emit("accomplish", state);
    };

    return {
      state,
      handleUpload,
      handleBack,
      handleDownloadTemplateFile,
      handleReject,
      handleCancel,
      handleConfirm,
      getErrorMsgKey,
    };
  },
};
</script>

<style lang="less" scoped></style>
