<template>
  <Content>
    <template #contentTitle>
      {{ $t("warehouse.add_shipment") }}
    </template>
    <template #contentBody>
      <a-spin size="large" :spinning="state.loading">
        <a-row>
          <a-col :span="24">
            <a-steps :current="1" style="max-width: 1800px" size="small">
              <a-step :title="$t('warehouse.create_appointment')" />
              <a-step :title="$t('warehouse.add_shipment')" />
              <a-step class="mr-3" :title="$t('warehouse.edit_shipment')" />
            </a-steps>
          </a-col>
        </a-row>

        <a-row class="mt-3" :gutter="[0, 12]">
          <a-col :span="8"
            >{{ $t("warehouse.transfer_warehouse") }}:
            {{ state.warehouseAddress?.warehouseNo }}</a-col
          >
          <a-col :span="16">
            {{ $t("warehouse.warehouse_address") }}:
            {{ getAddress() }}
            <span class="ml-2" v-if="state.warehouseAddress">
              {{ $t("logistics.contacts") }}:
              {{ getLinkman(state.warehouseAddress) }}
            </span>
          </a-col>
          <a-col :span="8">
            {{ $t("warehouse.type_of_shipping") }}:
            {{
              $t(
                $enumLangkey("transportationMethod", state.transportationMethod)
              )
            }}
          </a-col>
          <a-col
            :span="8"
            v-if="
              state.transportationMethod ==
              transportationMethodEnum.commercialExpress
            "
          >
            {{ $t("warehouse.delivery_way") }}:
            {{ $t($enumLangkey("deliveryWay", state.deliveryType)) }}
          </a-col>
          <a-col
            :span="8"
            v-if="
              state.transportationMethod ==
              transportationMethodEnum.commercialExpress
            "
          >
            {{ $t("warehouse.tracking_number") }}: {{ state.trackingNo }}
          </a-col>
          <a-col
            :span="8"
            v-if="
              state.transportationMethod == transportationMethodEnum.container
            "
          >
            {{ $t("warehouse.container_type") }}: 
            <span v-if="state.containerType">{{ $t($enumLangkey('containerType', state.containerType)) }}</span>
            <span v-else>-</span>
          </a-col>
          <a-col
            :span="8"
            v-if="
              state.transportationMethod == transportationMethodEnum.container
            "
          >
            {{ $t("warehouse.container_no") }}: {{ state.containerNo }}
          </a-col>
          <a-col :span="8">
            {{ $t("warehouse.estimated_arrival_date") }}:
            {{
              $filters.utcToSpecificTime(
                state.wareZoneAppointmentDate,
                state?.wareTimeZone
              )
            }}
          </a-col>
        </a-row>

        <a-row class="mt-5">
          <a-col :span="8">
            <div>
              <CUpload
                accept=""
                :planId="state.planId"
                @handleCancel="handleCancel"
                @accomplish="handleNextStep"
              ></CUpload>
            </div>
          </a-col>
        </a-row>
      </a-spin>
    </template>
  </Content>
</template>

<script>
import {
  defineComponent,
  reactive,
  onMounted,
  onActivated,
} from "vue";
import {
  Row,
  Col,
  Spin,
  Button,
  Steps,
} from "ant-design-vue";
import Content from "../../components/Content.vue";
import CPager from "../../components/CPager.vue";
import { getAddShipmentDetail, cancelPlan } from "../../../api/modules/transit/inPlan/plan";
import {
  transportationMethod as transportationMethodEnum,
  deliveryWay as deliveryWayEnum,
} from "../../../enum/enum.json";
import { getLinkman, getAddressByLanguageV2 } from "../../../utils/general";
import { useRouter, useRoute } from "vue-router";
import { useStore } from "vuex";
import { useTab } from "../../../hooks/tabs/index";
import CUpload from "./components/Upload.vue";

export default defineComponent({
  name: "transit_in_plan_add_shipment",
  components: {
    CUpload,
    Content,
    CPager,
    ARow: Row,
    ACol: Col,
    AButton: Button,
    ASpin: Spin,
    ASteps: Steps,
    AStep: Steps.Step,
  },
  setup() {
    const router = useRouter();
    const { getters } = useStore();
    const { delVisitedRoute } = useTab();
    const route = useRoute();

    const state = reactive({
      loading: false,
      planId: null,
      warehouseAddress: null,
      transportationMethod: null,
      deliveryType: null,
      trackingNo: null,
      containerType: null,
      containerNo: null,
      warehouseAddress: null,
      wareZoneAppointmentDate: null,
      wareTimeZone: null, //仓库时区
    });

    const handleCancel = () => {
      state.loading = true;
      cancelPlan(state.planId)
        .then(({ result }) => {
          state.loading = false;
          delVisitedRoute(router.currentRoute.value);
          router.push({
            name: "transit_in_plan_list",
          });
        })
        .catch(() => {
          state.loading = false;
        });
    };

    const handleNextStep = () => {
      delVisitedRoute(router.currentRoute.value);
      router.push({
        name: "transit_in_plan_edit",
        params: { id: state.planId },
      });
    };

    const getAddress = () => {
      if (!state.warehouseAddress) {
        return;
      }
      return getAddressByLanguageV2(state.warehouseAddress, getters.language);
    };

    const funcGetDetailById = () => {
      state.loading = true;
      getAddShipmentDetail(state.planId)
        .then(({ result }) => {
          Object.assign(state, result);
          state.loading = false;
        })
        .catch(() => {
          state.loading = false;
        });
    };

    onMounted(() => {
      state.planId = route.params.id;
      if (state.planId) {
        funcGetDetailById();
      }
    });

    onActivated(() => {});

    return {
      state,
      transportationMethodEnum,
      deliveryWayEnum,

      handleNextStep,
      handleCancel,
      getAddress,
      getLinkman,
    };
  },
});
</script>

<style lang="less" scoped></style>
